.btn {
  white-space: nowrap;
}

// Button Primary
.btn-primary {
  background-color: #7d63ec;
  border-color: #7d63ec;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #7d63ec !important;
  border-color: #7d63ec !important;
}

// Button Secondary
.btn-secondary {
  background-color: #00c8c4;
  border-color: #00c8c4;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background-color: #00c8c4 !important;
  border-color: #00c8c4 !important;
}

// Button Outline Primary
.btn-outline-primary {
  border-color: #7d63ec;
  color: #7d63ec;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background-color: #7d63ec !important;
  border-color: #7d63ec;
  color: white;
}

// Button Success
.btn-success {
  background-color: #0ac9b2;
  border-color: #0ac9b2;
  color: #ffffff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background-color: #0ac9b2 !important;
  border-color: #0ac9b2 !important;
  color: white;
}

// Button Outline Success
.btn-outline-success {
  background-color: #ffffff;
  border-color: #0ac9b2;
  color: #0ac9b2;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active {
  background-color: #0ac9b2 !important;
  border-color: #0ac9b2 !important;
  color: white;
}


// Button Purple
.btn-purple {
  color: #ffffff;
  background-color: #8828ff;
  border-color: #8828ff;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background-color: #8828ff !important;
  border-color: #8828ff !important;
}
