.bg-purple-img {
  // background-image: url(../../../assets/img/Home.png);
  background-image: url(https://booznostaticwebsitecdnendpoint.azureedge.net/Home.6121a42b199a037eb143.png);
  // background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 205px 0 80px 0;
  // height: 88vh;
}
.homepage-section1-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 70px;
  }
  p {
    font-size: 20px;
    line-height: 29px;
  }
  .homesection-bottom-utils-wrapper {
    display: flex;
    .title {
      font-size: 15px;
      margin-right: 10px;
    }
  }
  .btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }
}
.collection-sales-card {
  width: 100%;
  height: 100%;
  padding: 24px;
  border: 1px solid #e6e8f0;
  border-radius: 16px;
  text-align: center;
  transition: 0.2s;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 auto;
    height: 72px;
    width: 72px;
  }

  h5 {
    color: #20184e;
    font-size: 20px;
  }
  p {
    color: #666666;
    margin-bottom: 0px;
  }
}
.collection-sales-card:hover {
  border-radius: 16px !important;
  border: 1px solid #a689f09f;
  box-shadow: 0px 8px 24px 0px rgba(104, 95, 247, 0.12);
  // border-image: linear-gradient(to right, #e9e1fc, #875eea) 1;
}
.homepage-plan-card {
  padding: 25px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  .view-plan-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.view-plan-bg-img {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  // background-image: url(../../../assets/img/view-plans-img.png);
  background-image: url(https://booznostaticwebsitecdnendpoint.azureedge.net/view-plans-img.6b9b889bd1f6665a9e2f.png);
  background-size: cover;
  background-position: right top;
  border-radius: 16px;
  height: 100%;
  padding: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 120px 61px 17px inset;
}
.owl-carousel {
  .owl-item {
    img {
      width: auto !important;
    }

    .item {
      padding: 15px;
    }
  }
}
.owl-nav {
  button {
    border: 1px solid #27425b !important;
    color: #27425b !important;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
  }

  button:hover {
    color: #ffffff !important;
    border: 1px solid #7d63ec !important;
    background-color: #7d63ec !important;
  }
}
.testimonials-container {
  position: relative;
  // box-shadow: inset 400px 0 30px -20px white, inset -400px 0 30px -20px white;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.testimonials-container::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust these values to determine how much the shadow overlaps */
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-color: white; /* This should be the same color as the parent div's background */
  box-shadow: inset 260px 0 30px -20px white, inset -260px 0 30px -20px white;
  z-index: -1; /* This places the pseudo-element behind the content */
}
.testimonials-card {
  cursor: grab;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  .testimonial-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 30px;
  }

  img {
    width: 42px;
  }
}
.swiper-pagination {
  display: none;
}
.swiper-slide {
  width: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}
.better-list {
  .better-list-item {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 18px;

    .title {
      font-size: 18px;
      color: #555265;
      font-weight: 500;
    }
  }
}
.galaxy-img-wrapper {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
  }

  // circle:hover {
  //   box-shadow: #7c63ec36 0px 8px 24px;
  //   cursor: pointer;
  //   outline: 1px solid #7d63ec;
  //   border-radius: 50%;
  // }
}
.galaxy-img-wrapper-web {
  // margin-top: -80px;
}
.product-story-img {
  width: 142%;
  // width: 904px;
}

.save-strip-wrapper-bg {
  // background-image: url(../../../assets/img/pruple-strip.png);
  background-image: url(https://booznostaticwebsitecdnendpoint.azureedge.net/pruple-strip.9f87d5c9136a282abf68.png);
  background-position: center left;
  background-size: cover;
  font-size: 20px;
  margin: 90px 0 130px 0;
  .save-strip-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    img {
      width: 25px;
      margin-right: 8px;
    }
  }
}
.venue-managemnet-wrapper {
  background-color: #16141a;
  border-radius: 24px;
  h2 {
    position: relative;
    z-index: 9;
    top: 83px;
  }
  .venue-manage-img-wrapper {
    height: fit-content;
    display: flex;
    margin-top: -100px;
    video {
      width: 100%;
      border-radius: 30px;
    }
    img {
      width: 100%;
      border-radius: 30px;
    }
    svg {
      width: 100%;
    }
  }
}
.gradient-bg-left {
  background: linear-gradient(to right, #ffffffeb 40%, #ffffff00 100%);
  left: 0px;
  height: 100%;
  width: 150px;
  position: absolute;
  z-index: 9;
}
.gradient-bg-right {
  background: linear-gradient(to left, #ffffffeb 40%, #ffffff00 100%);
  right: 0px;
  top: 0px;
  height: 100%;
  width: 150px;
  position: absolute;
  z-index: 9;
}
.homepage-better-img {
  width: 110%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}
.homepage-product-story-content {
  p {
    color: #666666;
    line-height: 28.8px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}
.homepage-galaxy-venue-disc {
  color: #666666;
}
.homepage-readblogs-title {
  font-size: 34px;
}
@media screen and (max-width: 768px) {
  .homepage-section1-wrapper {
    h1 {
      font-size: 36px;
    }
    .homesection-bottom-utils-wrapper {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
  .galaxy-img-wrapper {
    margin-top: -50px;
  }
  .better-list {
    .better-list-item {
      .title {
        font-size: 16px;
        // margin-left: 5px !important;
      }
    }
  }
  .owl-carousel {
    .item {
      padding: 15px;
    }
  }
  .venue-managemnet-wrapper {
    h2 {
      top: 20px;
    }
  }
  .collection-sales-card {
    width: 95%;
  }
  .homepage-product-story-content {
    p {
      font-size: 16px;
    }
  }
  .homepage-section1-wrapper {
    .btns-wrapper {
      margin-bottom: 80px;
    }
  }
  .homesection-bottom-utils-wrapper {
    .title {
      font-size: 14px;
      margin-right: 0px;
    }
  }
  .better-list {
    .better-list-item {
      margin-bottom: 13px;
    }
  }
}
@media screen and (max-width: 992px) {
  .homepage-section1-wrapper {
    .btns-wrapper {
      flex-direction: column;
    }
    h1 {
      line-height: 53.2px;
      font-size: 38px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .homepage-product-story-content {
    p {
      color: #4d4d4d;
      line-height: 25.6px;
    }
  }
  .homepage-galaxy-venue-disc {
    color: #565656;
  }
  .bg-purple-img {
    // padding: 142px 0 50px 0;
    padding: 90px 0 0px 0;
    height: 90vh;
  }
  .product-story-img {
    width: 108%;
  }
  .venue-managemnet-wrapper {
    h2 {
      top: 60px;
      font-size: 32px !important;
    }
  }
  .homepage-better-img {
    width: 114%;
  }
  .better-list {
    .better-list-item {
      .title {
        font-weight: 500;
      }
    }
  }
  .homepage-plan-card {
    .view-plan-wrapper {
      justify-content: start;
    }
  }
  .homepage-readblogs-title {
    font-size: 20px;
  }
  .save-strip-wrapper-bg {
    font-size: 15px;
  }
  .collection-sales-card {
    width: 90%;
    height: fit-content;
    margin: 0;
    img {
      margin: 0 auto;
      height: 72px;
    }

    h5 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .homepage-boozno-better {
    h2 {
      margin-bottom: 50px !important;
    }
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
}
