.not-found-page {
  height: 100vh;
  .page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 44px;
    }
  }
}
.coming-soon-img {
  width: 80px;
}
