@media screen and (max-width: 768px) {
  .custom-btn {
    padding: 10px 25px !important;
  }
  // .banner-page-title {
  //   font-size: 36px !important;
  // }
  .scale-95 {
    transform: scale(1) !important;
  }
  .custom-page-tabs {
    justify-content: start !important;
    .tab-item {
      font-size: 14px !important;
      padding: 7px 24px !important;
      margin-right: 16px !important;
    }
  }
}
@media screen and (max-width: 992px) {
  .section-title {
    font-size: 36px !important;
    line-height: 50.4px !important;
    margin-bottom: 16px !important;
  }
  .section-subtitle {
    font-size: 16px !important;
    line-height: 25.6px !important;
  }
  .custom-btn {
    width: 252px !important;
  }
  .fs-lg-28 {
    font-size: 28px !important;
    line-height: 36.4px !important;
  }
}
@media screen and (min-width: 992px) {
  .h-lg-100vh {
    height: 100vh !important;
  }
  .border-bottom-lg-none {
    border-bottom: none !important;
  }
  .border-right-lg {
    border-right: 1px solid;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .section-title {
    font-size: 40px !important;
  }
  .homepage-boozno-better-title {
    font-size: 37px !important;
  }
  .homepage-boozno-better-list-item {
    .title {
      font-size: 16px !important;
    }
  }
}
