.custom-modal-wrapper {
  .custom-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -37px;
  }
  .modal-header {
    border: none !important;

    .btn-close {
      margin-top: 0px;
      margin-right: 0px;
    }
  }
  .modal-dialog {
    max-width: 600px !important;
  }
  .modal-content {
    border-radius: 16px !important;
    width: 550px !important;
    height: 470px !important;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 170px;
      margin-bottom: 29px;
    }
  }
  .thankyou-modal-title {
    font-size: 64px;
    margin-bottom: 24px;
  }
  .btn-close {
    margin-top: 10px;
    width: 11px;
    height: 11px;
    background-image: url(../../../assets/img/cross-icon.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
    position: relative;
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 992px) {
  .custom-modal-wrapper {
    .modal-dialog {
      max-width: 600px !important;
    }
    .modal-content {
      border-radius: 16px !important;
      width: 600px !important;
      height: 370px !important;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 125px;
        margin-bottom: 20px;
      }
    }
    .thankyou-modal-title {
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
}
