.ready-img {
  width: 65%;
}
.ready-container {
  position: relative;
  border-radius: 10px;

  .ready-subtitle {
    font-size: 22px;
    margin-bottom: 32px;
  }

  .bg-pink {
    background-color: #eeeaff;
    // background-color: pink;
    width: 100%;
    height: 295px;
    position: absolute;
    z-index: -1;
    bottom: 0px;
    border-radius: 15px;
  }
}
.ready-title {
  font-size: 48px;
  margin-bottom: 16px;
}
.ready-cta {
  padding: 16px 32px !important;
  font-size: 18px;
}
@media screen and (max-width: 992px) {
  .ready-img {
    width: 55%;
    margin-top: -140px;
  }
  .ready-container {
    // padding: 0 10px;
    .bg-pink {
      height: 100%;
    }
  }
  .ready-title {
    font-size: 32px;
    margin-bottom: 12px;
  }
  .ready-cta {
    padding: 16px 24px !important;
    font-size: 18px;
  }
  .ready-title {
    font-size: 36px;
    line-height: 50.4px;
  }
  .multi-device-mobile-text-wrapper {
    padding: 0 24px;
  }
  .coming-soon-btn {
    font-size: 15px !important;
    width: 140px;
  }
  .benefit-item {
    .text-dark-blue {
      font-size: 14px;
    }
  }
}
