.custom-faq-accordion {
  .accordion-item {
    margin-bottom: 15px;
    background-color: #f8f8f8;
    border: none;
    border-radius: 7px;
  }
  .accordion-button {
    font-family: "Red Hat Semi-bold", sans-serif;
    border-radius: 7px;
    background-color: #f8f8f8;
    font-weight: 500;
    box-shadow: none !important;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button::after {
    background-image: url(../../../assets/img/faq-plus-icon.png);
    background-size: cover;
    width: 15px;
    height: 15px;
  }
  .accordion-button:not(.collapsed):after {
    background-image: url(../../../assets/img/faq-plus-icon.png);
    transform: rotate(45deg);
  }
  .accordion-button:not(.collapsed) {
    font-family: "Red Hat Bold", sans-serif;
    background-color: #f8f8f8;
  }
}
// .custom-page-tabs {
//   overflow-x: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .tab-item {
//     font-family: "Red Hat Semi-bold", sans-serif !important;
//     white-space: nowrap;
//     padding: 8px 30px;
//     background-color: #f2f2f2;
//     border-radius: 45px;
//     color: #666666;
//     margin-right: 15px;
//     cursor: pointer;
//   }
//   .tab-item.active {
//     background-color: #7d63ec;
//     color: #ffffff;
//   }
// }
// .custom-page-tabs::-webkit-scrollbar {
//   display: none;
// }

@media screen and (max-width: 768px) {
  .accordion-button::after {
    width: 11px !important;
    height: 11px !important;
  }
}
