.custom-nav-tabs {
}
.custom-tab-wrapper {
  // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  // height: fit-content;
  // height: 610px;
  height: 640px;
  transition: 1s;
  .custom-tab-list {
    min-width: 435px;
    width: 40%;
    background-color: #1e1b26;
    padding: 45px 35px 20px 20px;
    border-radius: 45px 0 0 45px;

    .custom-tab-item {
      transition: 0.3s;
      transform: translate(0px, 0px);
      position: relative;
      width: 280px;
      cursor: pointer;
      border-radius: 45px;
      padding: 11px 11px 11px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      .item {
        display: flex;
        align-items: center;
      }

      .title {
        white-space: nowrap;
        margin-left: 15px;
        color: #ffffff;
        opacity: 0.6;
        font-size: 18px;
      }
    }
    // .custom-tab-item:hover {
    //   .title {
    //     opacity: 1;
    //   }
    // }
    .custom-tab-item.active {
      background-color: #7d63ec;
      transform: translate(30px, 0px);
      .title {
        opacity: 1;
        font-family: "Red Hat Semi-bold", sans-serif;
      }
    }
  }

  .custom-tab-content {
    background-image: url(../../../../assets/img/tab-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 40px;
    padding-left: 65px;
    margin-left: -137px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 45px;
  }
}
.custom-tab-content-container {
  h4 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .tab-content {
    color: #4a4a4a;
    font-family: "Red Hat Regular", sans-serif;
    line-height: 28px;
    font-size: 14px;
  }
  .tab-content-img {
    width: 100%;
  }
  .tab-item-list-text {
    font-size: 14px;
  }
}
.solution-tab-item-title {
  font-size: 14px;
  color: #313131;
  line-height: 18.52px;
}
.tab-content-list-item {
  display: flex;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 768px) {
  .custom-tab-wrapper {
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .custom-tab-list {
      overflow: auto;
      display: flex;
      align-items: start;
      width: 100%;
      padding: 10px 10px 20px 10px;
      border-radius: 16px 16px 0 0;
      min-width: auto;

      .custom-tab-item {
        border-radius: 13px;
        flex-direction: column;
        width: fit-content;
        padding: 11px 20px 0px 20px;
        margin: 0;
        height: 53px;
        .title {
          letter-spacing: 0.5px;
          margin-left: 10px;
          font-size: 16px;
        }
      }

      .active {
        z-index: 99;
        transform: translate(0px, 0px) !important;
      }
    }
    .custom-tab-list::-webkit-scrollbar {
      display: none;
    }
    .custom-tab-content {
      padding: 40px 20px;
      margin: 0;
      margin-top: -24px;
      width: 100%;
      border-radius: 16px;
      z-index: 9;
    }
  }
  .right-arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-top: 1px;
    background-color: #7d63ec;
    border-radius: 50%;
    padding: 4px;
    position: relative;
    bottom: -6px;
  }
  .right-arrow-wrapper svg {
    width: 100px;
    transform: rotate(90deg); /* Rotates the SVG */
  }
  .right-arrow-wrapper::before {
    transition: 0.3s;
    background-image: url(../../../../assets/img/triangle-before.png); /* Corrected order */
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 9px;
    right: 27px;
    z-index: 1;
    transform: rotate(-180deg);
  }
  .right-arrow-wrapper::after {
    transition: 0.3s;
    background-image: url(../../../../assets/img/triangle-after.png);
    content: "";
    width: 11px;
    height: 11px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    right: -10px;
    transform: rotate(-89deg);
    z-index: 1;
  }
}
@media screen and (max-width: 992px) {
  .custom-tab-content-container {
    img {
      // width: 100%;
    }
  }
  .custom-tab-wrapper {
    height: fit-content;
    width: 97%;
    margin: auto;
  }
  .custom-tab-wrapper {
    .custom-tab-list {
      .custom-tab-item {
        transition: none;
      }
      .active {
        transform: translate(0px, 0px) !important;
      }
    }
    .custom-tab-list::-webkit-scrollbar {
    }
    .custom-tab-content {
    }
  }
  .custom-tab-content-container {
    .tab-content {
      line-height: 24px;
    }
  }
  .tab-content-list-item {
    display: flex;
    align-items: start;
    img {
      margin-top: 3px;
      width: 15px;
    }
  }
}
