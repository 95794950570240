footer {
  .bg-blue {
    background-color: #1e1b26;
  }
  .bg-dark-blue {
    background-color: #17151e;
  }
  .footer-link-title {
    font-family: "Red Hat Semi-bold", sans-serif;
    margin-bottom: 20px;
    font-size: 20px;
  }
  .footer-links {
    a {
      text-decoration: none;
    }
    .item {
      margin-bottom: 12px;
      color: #adabb7ab;

      .main-icon {
        width: 16px;
      }
    }
    .item:hover {
      color: #ffffffd4;
      cursor: pointer;
    }
  }
  .footer-socialmedia-items {
    display: flex;
    align-items: center;
    justify-content: start;
    .twitter-icon {
      width: 26px !important;
      height: 26px !important;
    }
    .item {
      margin-right: 30px;
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
  .footer-subscribe-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ffffff5d;
    border-radius: 45px;
    width: fit-content;
    background-color: #25212e;
    padding: 5px;
    width: 100%;
    input {
      width: 100%;
      color: #ffffff5d;
      padding: 0 15px 0 15px;
      border: none;
      background-color: #25212e;
    }
    input:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
    button {
      border-radius: 45px;
      padding: 10px 20px;
    }
  }
  .footer-copyright-text {
    font-size: 18px;
    padding: 18px 0;
  }
  .w-23 {
    width: 23px;
  }
}
@media screen and (max-width: 768px) {
  .footer-copyright-text {
    font-size: 14px !important;
    padding: 14px 0;
  }
  .footer-socialmedia-items {
    .item {
      margin-right: 33px !important;
    }
  }
  footer {
    .footer-container {
      padding: 0 24px !important;
    }
    .w-23 {
      width: 20px;
    }
    .footer-link-title {
      font-size: 19px;
    }
    .footer-links {
      .item {
        font-size: 15px;
      }
    }
  }
}
