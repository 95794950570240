.legal-tab-content {
  text-align: justify;
  p {
    font-size: 18px;
    color: rgba(102, 102, 102, 1);
    line-height: 28.8px;
    font-family: "Red Hat Light";
  }
  h5 {
    font-size: 24px;
    color: rgba(32, 24, 78, 1);
    line-height: 38.4px;
    font-family: "Red Hat Bold";
    // margin-bottom: 40px;
  }
  .text-title-grey {
    color: rgba(102, 102, 102, 1);
  }
  h6 {
    font-size: 22px;
    // color: rgba(32, 24, 78, 1);
    color: rgba(102, 102, 102, 1);
    line-height: 38.4px;
    font-family: "Red Hat Bold";
    // margin-bottom: 40px;
  }
  h4 {
    font-size: 20px;
    color: rgba(102, 102, 102, 1);
    line-height: 38.4px;
    font-family: "Red Hat Bold-italic";
    font-weight: bold;
    // margin-bottom: 40px;
  }
  .text-blue {
    color: #0075ff;
  }
}
.legal-tab-info-wrapper {
  .no-numbers {
    list-style-type: none;
    li {
      p {
        margin-bottom: 10px;
      }
    }
  }
  margin-bottom: 32px;
  li {
    p {
      margin-bottom: 0px;
    }
  }
}
.legaltabs-page-title {
  font-size: 48px;
  margin-bottom: 16px;
}
.legaltabs-page-subtitle {
  font-size: 18px;
  margin-bottom: 36px;
  line-height: 28.8px;
  color: rgba(102, 102, 102, 1);
}
@media screen and (max-width: 768px) {
  .legal-tab-content {
    p {
      font-size: 14px;
      line-height: 22.4px;
    }
    h5 {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .legaltabs-page-title {
    font-size: 30px !important;
    margin-bottom: 8px !important;
  }
  .legaltabs-page-subtitle {
    font-size: 16px !important;
    margin-bottom: 26px !important;
    line-height: 25.6px !important;
    color: rgba(102, 102, 102, 1);
  }
}
