header {
  //   background-color: purple;
  height: 80px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;

  nav {
    height: 100%;
  }
  .header-logo {
    // width: 100px;
  }
  .header-components {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .header-component-item {
      margin-left: 30px;
    }
  }
  .header-items {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
    a {
      text-decoration: none;
    }

    .item {
      margin: 0 15px;
      cursor: pointer;
      color: #ffffff;
    }
  }
  .header-items.homepage-header-items {
    .item {
      color: #a6a2b6;
      font-size: 18px;
    }
  }
  .talk-to-sales-wrapper {
    display: flex;
    align-items: center;
    margin: auto 0;
  }
}
header {
  .item.active {
    color: #5b5769 !important;
    opacity: 1;
    font-family: "Red Hat Bold";
  }
}
.custom-header {
  transition: 0.2s;
}
.header-menu-offcanvas {
  width: 77% !important;
  max-width: 350px !important;
  border-radius: 20px 0 0 20px;
  z-index: 9999 !important;

  .header-offcanvas-body-wrappper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }
  .header-menu-offcanvaslist {
    width: 100%;
    a {
      text-decoration: none;
    }
    .list-item {
      font-size: 18px;
      padding: 10px 0;
      margin-bottom: 10px;
      color: #494949;
      opacity: 0.7;
      border-bottom: 1px solid #e6e8f0;
    }
  }
  .list-item.active {
    opacity: 1;
    color: #5b5769;
    font-family: "Red Hat Bold", sans-serif;
  }
  .btn-close {
    margin-top: 10px;
    width: 8px;
    height: 8px;
    background-image: url(../../../assets/img/cross-icon.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
  }
  .btn-close:focus {
    box-shadow: none !important;
  }
}
.custom-bg-header {
  transition: 0.2s;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.custom-bg-header.homepage {
  background-color: rgba(0, 0, 0, 0.349);
}
.header-signup-btn {
  padding: 10px 29px !important;
}

// Fixes
.homepage {
  .header-items {
    .item {
      color: #ffffff;
      font-size: 18px;
    }
    .item.active {
      color: #ffffff !important;
    }
  }
}
.homepage.custom-bg-header {
  background-color: white;
  .header-items {
    .item {
      color: #a6a2b6 !important;
    }
    .item.active {
      color: #5b5769 !important;
      font-family: "Red Hat Bold";
    }
  }
}
.text-to-sales-text-header {
}

@media screen and (max-width: 768px) {
  header {
    .logo-wrapper {
      svg {
        width: 85%;
      }
    }
    .header-components {
      .header-component-item {
        margin-left: 10px;
      }
    }
  }
  .custom-header {
    transition: 0.3s;
  }
}
@media screen and (max-width: 992px) {
  header {
    height: 70px;
    .text-to-sales-text-header {
      font-size: 14px;
      font-family: "Red Hat Semi-bold", sans-serif;
    }
  }
}
