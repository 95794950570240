.right-answer-wrapper {
  border-radius: 10px;
  padding: 32px 40px;

  h3 {
    font-size: 36px;
  }
  p {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .right-answer-title {
    font-size: 32px;
  }
  .right-answer-wrapper {
    padding: 20px;
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }
}
