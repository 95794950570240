.switch-input-wrapper {
  .form-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    .form-check-input {
      background-color: #7d63ec;
      border-color: #7d63ec;
      height: 21px;
      width: 36px;
      margin-bottom: 4px;
      cursor: pointer;
    }
    .form-check-input:focus {
      box-shadow: none;
    }
  }
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url(../../../assets/img/white-circle.svg) !important;
}
.pricing-banner-title {
  font-size: 48px;
  text-transform: capitalize;
  text-align: center;
  line-height: 67.2px;
}
.pricing-banner-subtitle {
  margin-bottom: 18px;
}
.save-offer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  img {
    width: 16px;
  }
  .text {
    color: #ff647c;
    font-size: 14px;
  }
}
.plan-card {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
  font-family: "Lato Regular", sans-serif;
  padding: 32px 35px;
  .venues {
    font-family: "Lato Semi-bold", sans-serif;
    font-size: 14px;
  }
  .top-wrapper {
    margin-bottom: 12px;
  }
  button {
    font-family: "Red Hat Regular", sans-serif;
  }
  h5 {
    font-size: 24px;
  }
  h6 {
    font-size: 14px;
    margin-bottom: 17px;
  }
  h5,
  h6 {
    font-family: "Lato Bold", sans-serif;
  }
  .text-purple {
    margin-bottom: 25px;
    .mb-1 {
      font-size: 14px;
    }
  }
  .title {
    font-family: "Lato Bold", sans-serif;
    // font-family: "Lato Black", sans-serif;
    font-size: 24px;
    white-space: nowrap;

    del {
      font-size: 22px;
      margin-top: 3px;
    }
  }
  .card-list-text {
    font-size: 14px;
    font-family: "Lato Semi-bold", sans-serif;
    color: #20184e;
    margin-left: 12px;
  }
  .card-list-text2 {
    font-size: 15px;
    // font-family: "Red Hat Semi-bold", sans-serif;
    font-family: "Lato Semi-bold", sans-serif;
    // color: #666666;
    color: #20184e;
  }
}
.no-of-users-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  .counter-wrapper {
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-wrapper {
    user-select: none;
    padding: 0 10px;
    cursor: pointer;
  }
  .br {
    border-right: 1px solid lightgrey;
  }
  .bl {
    border-left: 1px solid lightgrey;
  }
  .text {
    font-family: "Lato Semi-bold", sans-serif;
  }
}
.border-purple {
  border-radius: 10px;
  border: 1px solid #a894ff;
  background: #fff;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.12);
}
.plans-table-titles {
  font-size: 17px;
  .title {
    font-size: 24px;
    font-family: "Lato Semi-bold";
  }
}
.plans-list-wrapper {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 10px;

  .list-item {
    border-bottom: 1px solid rgb(102 102 102 / 13%);
    padding: 15px 0;

    .text-grey3 {
      font-size: 16px;
      font-family: "Lato Regular";
    }
  }
}
.pricing-2-cards-card {
  min-height: 163px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .custom-bg-incard {
    position: absolute;
    width: 100%;
    // height: 121%;
    height: 187px;
    top: -40px;
    z-index: -1;
    border-radius: 15px;
    box-shadow: rgb(124 99 236 / 7%) 0px -35px 92px;
  }
}
.pricing-values-card {
  height: 100%;
  position: relative;
  .card-white-bg {
    position: absolute;
    width: 100%;
    height: 77%;
    bottom: 0px;
    z-index: -1;
    border-radius: 15px;
    box-shadow: #7c63ec18 0px -51px 92px;
  }
}
.pricing-click-here-wrapper {
  font-family: "Lato Semi-bold", sans-serif;
  font-size: 18px;
}
.pricing-subscription-cards {
  .collection-sales-card {
    // box-shadow: #7c63ec18 0px 0px 92px;
    background-color: #ffffff;
  }
}
.pricing-subscribe-title {
  line-height: 74px;
  margin-bottom: 40px;
}
@media screen and (max-width: 992px) {
  .plans-table-title {
    font-size: 16px;
  }
  .plans-table-titles {
    font-size: 13px;
  }
  .plans-list-wrapper {
    .list-item {
      .text-grey3 {
        font-size: 14px;
        font-family: "Red Hat Semi-bold";
      }
    }
  }
  .pricing-subscription-cards .collection-sales-card {
    box-shadow: none;
  }
  .pricing-banner-title {
    font-size: 30px !important;
    line-height: 50.4px !important;
    margin-bottom: 12px !important;
  }
  .pricing-banner-subtitle {
  }
  .pricing-switch-items {
    font-size: 14px;
  }
  .plan-card {
    .venues {
      font-family: "Lato Regular", sans-serif;
      font-size: 14px;
    }
  }
  .plan-card {
    .card-list-text {
      font-family: "Lato Regular", sans-serif;
    }
  }
  .pricing-2-cards-card {
    .text-grey2 {
      font-size: 14px;
      line-height: 22.4px;
    }
    button {
      font-size: 14px;
      font-family: "Red Hat Regular", sans-serif !important;
    }
  }
  .pricing-values-card {
    .card-white-bg {
      height: 84%;
    }
    .text-grey2 {
      line-height: 22.4px;
      font-size: 14px !important;
    }
    .collection-sales-card {
      width: 100%;
    }
    img {
      width: 80px;
    }
  }
  .pricing-every-step-title {
    line-height: 57.6px !important;
  }
  .pricing-subscription-cards-new {
    .collection-sales-card {
      padding: 20px !important;
    }
    h5 {
      margin-bottom: 8px !important;
      font-size: 20px;
    }
    p {
      // font-size: 16px !important;
      // line-height: 25.6px;
    }
  }
  .pricing-subscribe-title {
    font-size: 36px;
    line-height: 57.6px;
    margin-bottom: 28px !important;
  }
  .plans-table-titles {
    .title {
      font-size: 14px;
    }
  }
  .pricing-values-card {
    p {
      font-size: 16px !important;
      line-height: 25.6px;
      font-family: "Red Hat Light";
    }
  }
  .pricing-values-card {
    .card-white-bg {
      box-shadow: rgb(124 99 236 / 6%) 0px -51px 92px;
    }
  }
}
