.solution-first-section {
  height: 100vh;
  .section-subtitle {
    line-height: 32px;
  }
}
.banner-page-title {
  font-size: 58px;
  margin-bottom: 15px;
  line-height: 78px;
}
.solutions-banner-subtitle {
  color: #605f5f !important;
  line-height: 32px;
}
.solutions-multi-device-section-title {
  color: #000000 !important;
  line-height: 67.2px;
  margin-bottom: 15px !important;
}
.solutions-multi-device-section-subtitle {
  color: #000000 !important;
  /* line-height: 28.8px; */
}
.solution-privacy-content {
  font-size: 22px;
  line-height: 35.2px;
  color: #666666;
}
.solution-values-card {
  img {
    width: 102px;
    height: auto;
  }
}
.menu-device-support-wrapper {
  border-radius: 16px;
  background-image: url(../../../assets/img/multi-bg-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  img {
    width: 80%;
  }
}
.solution-banner-img {
  // position: relative;
  // right: -50px;
}
.benefits-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 20px;
}
.benefits-bg {
  background-image: url(../../../assets/img/benefits-bg.png);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 79%;
  top: 0;
  z-index: -1;
  border-radius: 15px;
  box-shadow: rgb(125 99 236 / 0%) 0px 4px 16px, rgb(125 99 236 / 48%) 0px 8px 32px;
}
.solutions-benefits-wrapper {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 40px 0px rgba(43, 89, 255, 0.08);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  width: -webkit-fill-available;
  margin: 0 50px;
  .benefit-item {
    padding: 10px;
  }
}
.perc-title {
  font-family: "Red Hat Bold", sans-serif;
  font-size: 56px;
  margin-bottom: 3px;
  color: #6143e1;
}
.custom-values-card {
  position: relative;
  top: 50px;
}
.solution-privacy-wrapper {
  border-radius: 50px;
  padding: 40px;
  // background-color: #f9f7fc;
  background-image: url(../../../assets/img/privacy-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.start-now-btn {
  padding: 16px 32px !important;
  font-size: 18px !important;
}
.get-started-cta {
  padding: 16px 32px !important;
  font-size: 18px !important;
}
.bordered-div {
  border-color: white !important;
  position: relative;
}

.bordered-div::before {
  content: "";
  position: absolute;
  right: -1px;
  top: 30%;
  height: 43%;
  width: 2px;
  background-color: #eaeeff;
  transform: translateX(50%);
}
.custom-solution-banner-row {
  width: 100%;
}
@media screen and (max-width: 992px) {
  .get-started-cta {
    font-size: 14px !important;
  }
  .solution-banner-img {
    position: static;
  }
  .solution-first-section {
    margin-top: 50px;
    height: auto;
  }
  .benefits-section-wrapper {
    padding-top: 0px;
  }
  .benefits-bg {
    background: none;
    box-shadow: none;
  }
  .benefits-bg-mobile {
    background-image: url(../../../assets/img/benefits-bg-mobile.png);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    height: 69%;
    width: 100%;
    position: absolute;
    top: 0px;
  }
  .solutions-benefits-wrapper {
    padding: 20px;
    width: 98%;
  }
  .perc-title {
    font-family: "Red Hat bold";
    font-size: 32px;
    margin-bottom: 3px;
  }
  .values-cards-wrapper {
    .collection-sales-card {
      padding: 10px 2px 0 2px;
      img {
        // width: 65%;
      }
      .values-cards-wrapper .collection-sales-card h5 {
        font-size: 18px;
        margin-bottom: 8px !important;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .custom-values-card {
    position: static;
  }
  .menu-device-support-wrapper {
    h2 {
      font-size: 36px !important;
    }
    img {
      width: 100%;
    }
  }
  .banner-page-title {
    margin-top: 30px;
    font-size: 36px;
    margin-bottom: 15px;
    line-height: 50.4px;
  }
  .solution-values-card {
    width: 100%;
    height: 100%;
    padding: 12px !important;
    justify-content: start;
  }
  .solution-privacy-content {
    font-size: 16px;
    line-height: 25.6px;
    color: #666666;
  }
  .solution-privacy-wrapper {
    background-image: url(../../../assets/img/privacy-mobile-bg.png);
    border-radius: 16px;
    padding: 40px 15px;
    background-position: top center;
  }
  .startnow-btn-wrapper {
    a {
      width: 100%;
      .start-now-btn {
        width: 100%;
        font-size: 14px !important;
        padding: 10.5px 24px !important;
      }
    }
  }
  .bordered-div {
    border-color: rgba(128, 128, 128, 0.3607843137) !important;
    position: relative;
  }
  .bordered-div::before {
    display: none;
  }
  .custom-solution-banner-row {
    width: auto;
  }
}
