.culture-wrapper {
  background-image: url(../../../assets/img/benefits-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-radius: 30px;
  padding: 55px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-subtitle {
    font-size: 22px;
    line-height: 35.2px;
    font-family: "Red Hat Light";
  }
}
.aboutus-custom-title {
  text-transform: capitalize;
  text-align: center;
  line-height: 67.2px;
  font-size: 42px;
  margin-top: 45px;
  margin-bottom: 78px;
}
.culture-detail-text {
  font-size: 20px;
}
.aboutus-page-title {
}
.about-us-content {
  font-size: 18px;
  line-height: 28.8px;
  color: #666666;
}
.join-us-cta {
  padding: 16px 32px !important;
  font-size: 18px;
}
.founder-story-title {
  font-size: 36px;
}
@media screen and (max-width: 992px) {
  .culture-wrapper {
    padding: 45px 26px;
  }
  .aboutus-custom-title {
    margin-top: 30px;
    font-size: 24px;
    line-height: 33.6px;
    margin-bottom: 56px;
  }
  .culture-detail-text {
    font-size: 16px;
  }
  .about-us-content {
    font-size: 16px;
    line-height: 25.6px;
    padding: 0 10px;
  }
  .founder-story-title {
    font-size: 24px;
  }
}
