.custom-faq-accordion {
  .accordion-item {
    margin-bottom: 15px;
    background-color: #f8f8f8;
    border: none;
    border-radius: 7px;
  }
  .accordion-button {
    font-family: "Red Hat Semi-bold", sans-serif;
    border-radius: 7px;
    color: #20184e !important;
    background-color: #f8f8f8;
    font-weight: 500;
    box-shadow: none !important;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button::after {
    background-image: url(../../../assets/img/faq-plus-icon.png);
    background-size: cover;
    width: 15px;
    height: 15px;
  }
  .accordion-button:not(.collapsed):after {
    background-image: url(../../../assets/img/faq-plus-icon.png);
    transform: rotate(45deg);
  }
  .accordion-button:not(.collapsed) {
    font-family: "Red Hat Bold", sans-serif;
    background-color: #f8f8f8;
  }
  .accordion-body {
    color: #666666;
    p {
      color: #666666;
      line-height: 29px;
    }
    strong {
      font-family: "Red Hat Bold", sans-serif !important;
    }

    a {
      color: #0075ff !important;
    }
  }
  .faq-subtitle {
    margin-bottom: 40px;
  }
}
