// Red Hat Regular Font
@font-face {
  font-family: "Red Hat Regular";
  src: url("../font/red-hat-display/RedHatDisplay-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Red Hat Bold";
  src: url("../font/red-hat-display/RedHatDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Red Hat Semi-bold";
  src: url("../font/red-hat-display/RedHatDisplay-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Red Hat Extra-bold";
  src: url("../font/red-hat-display/RedHatDisplay-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Red Hat Light";
  src: url("../font/red-hat-display/RedHatDisplay-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Red Hat Italic";
  src: url("../font/red-hat-display/RedHatDisplay-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Red Hat Bold-italic";
  src: url("../font/red-hat-display/RedHatDisplay-BoldItalic.ttf") format("truetype");
}
// Lato Font
@font-face {
  font-family: "Lato Regular";
  src: url("../font/lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Bold";
  src: url("../font/lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Light";
  src: url("../font/lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Italic";
  src: url("../font/lato/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Semi-bold";
  src: url("../font/lato/Lato-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato Black";
  src: url("../font/lato/Lato-Black.ttf") format("truetype");
}
// @font-face {
//   font-family: "Lato Extra-bold";
//   src: url("../font/lato/") format("truetype");
// }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Red Hat Regular", sans-serif;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Red Hat Bold", sans-serif;
}
a {
  text-decoration: none;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-white {
  color: #ffffff;
}
.text-black {
  color: #000000;
}
.text-light {
  color: #adabb7ab !important;
}
.text-grey2 {
  color: #666666;
}
.text-grey3 {
  color: #5d5e62;
}
.text-light-grey {
  color: #888888;
}
.text-grey {
  color: #494949;
}
.text-semigrey {
  color: #4a4a4a;
}
.text-darkgrey {
  color: #313131;
}
.text-darkgrey2 {
  color: #1c1d21;
}
.text-greyheader {
  color: #8a869c;
}
.text-purple {
  color: #7d63ec;
}
.text-light-white {
  color: #aaaaaa;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 15px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-34 {
  font-size: 34px;
}
.w-80 {
  width: 80%;
}
.w-98 {
  width: 98px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.text-dark-blue {
  color: #20184e;
}
.box-shadow-none {
  box-shadow: none !important;
}
.border-rad-none {
  border-radius: 0px !important;
}
.border-rad-30 {
  border-radius: 30px;
}
.border-rad-45 {
  border-radius: 45px;
}
.border-rad-12 {
  border-radius: 12px;
}
.margin-top-minus88 {
  margin-top: -88px;
}
.custom-btn {
  padding: 10px 0;
  width: 218px;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.section-title {
  font-size: 48px;
  text-transform: capitalize;
  text-align: center;
  // margin-bottom: 20px;
  line-height: 67.2px;
}
.section-subtitle {
  line-height: 28.8px;
  text-align: center;
  color: #666666;
  font-size: 18px;
}
.bg-beige {
  background-color: #fff7ea;
}
.bg-light-blue {
  background-color: #dff7fc;
}
.bg-light-purple {
  background-color: #f6f3fa;
}
.bg-purple-light {
  background-color: #ededff;
}
.bg-purple {
  background-color: #7d63ec;
}
.view-plan-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 45px;
  padding: 7px 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-nodrop {
  cursor: no-drop !important;
}
.scale-95 {
  transform: scale(0.95);
}
.border-none {
  border: none !important;
}
.border-right {
  border-right: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-grey {
  border-color: #8080805c;
}
.border-right-none {
  border-right: none;
}
.fw-bold {
  font-family: "Red Hat Bold", sans-serif !important;
}
.fw-semibold {
  font-family: "Red Hat Semi-bold", sans-serif !important;
}
.fw-lato-black {
  font-family: "Lato Black" !important;
}
.mb-6 {
  margin-bottom: 90px;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.no-select {
  user-select: none; /* Standard property */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer and Edge (legacy) */
}

// Tabs
.custom-page-tabs {
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
  }
  .tab-item {
    font-family: "Red Hat Regular", sans-serif !important;
    white-space: nowrap;
    padding: 9.5px 32px;
    background-color: #f2f2f2;
    border-radius: 45px;
    color: #666666;
    margin-right: 24px;
    cursor: pointer;
    font-size: 18px;
  }
  .tab-item.active {
    background-color: #7d63ec;
    color: #ffffff;
  }
}
.custom-page-tabs::-webkit-scrollbar {
  display: none;
}
