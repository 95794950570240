.custom-form-input {
  padding: 11px 20px !important;
  border-radius: 45px !important;
}
.custom-form-input:focus,
textarea:focus {
  box-shadow: none !important;
}
.invalid-feedback {
  font-style: italic;
  color: rgba(255, 0, 0, 1) !important;
}
.custom-form-input.is-invalid {
  border-color: rgba(255, 0, 0, 0.6) !important;
  background: none !important;
}
.dynamic-form-wrapper {
  // background-image: url(../../../assets/img/forms-bg.png);
  background-image: url(https://booznostaticwebsitecdnendpoint.azureedge.net/forms-bg.f618c5107a517f5ecb0b.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
  margin-top: 70px;
  width: 90%;
  border-radius: 30px;
  padding: 64px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

  textarea {
    border-radius: 16px;
    max-height: 75px;
  }
}
.form-img-wrapper {
  img {
    width: 80px;
  }
}
.custom-gradient {
  position: absolute;
  background: linear-gradient(to left, rgb(125 99 236 / 15%) 0%, rgb(255 255 255 / 0%) 108%);
  right: 0px;
  top: 0px;
  height: 100%;
  width: 150px;
  border-radius: 0 30px 30px 0;
}
.custom-forms {
  label {
    font-size: 18px;
    margin-bottom: 12px;
  }
  input,
  .form-select {
    color: rgba(102, 102, 102, 1) !important;
    border: 1px solid rgba(73, 73, 73, 0.3);
  }
  input::placeholder {
    font-size: 18px;
    color: rgba(102, 102, 102, 1) ss;
  }
}
select {
  width: 100%;
}
select option {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
}
.selected-option:active {
  color: #ffffff;
  background-color: #7d63ec !important; /* Custom background color for selected option */
}
.custom-form-input {
  width: 100%; /* Ensure the select element takes the full width of its container */
  max-width: 100%; /* Prevent the select element from exceeding the container width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}
.custom-form-input option {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
}
.dynamic-form-wrapper {
  .section-subtitle {
    color: rgba(102, 102, 102, 1);
    margin-bottom: 50px;
  }
}
.forms-common-design {
  h6 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
    line-height: 25.6px;
    font-family: "Red Hat Light";
  }
}
.get-touch-btn {
  font-size: 18px !important;
  padding: 16px 32px !important;
}
.left-form-wrapper {
  width: 90%;
}
option {
  background-color: white !important;
  color: black !important;
}

@media screen and (max-width: 992px) {
  .dynamic-form-wrapper {
    margin-top: 50px;
    width: 100%;
    padding: 25px;
    background: none;
    border-radius: 16px;
    border: 1px solid #f8f2ff;
    background: #fff;
    box-shadow: 0px 0px 44px 0px rgba(20, 11, 72, 0.08);
    .section-subtitle {
      margin-bottom: 40px;
    }
  }
  .custom-gradient {
    display: none;
  }
  .left-form-wrapper {
    width: 100%;
  }
}
